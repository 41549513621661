export const MILI = [
  'participation of two representatives of the sponsor in the exhibition',
  'a stand with a desk',
  'company profile, logo, and contact information on conference website and in conference program',
  'participation in events – Welcome reception and Gala dinner',
  'full board with coffee breaks and refreshments',
  'welcome package',
]

export const MICRO = [
  'advertisment during coffee breaks',
  'banner and company flyers',
  'company profile, logo, and contact information on conference website and in conference program',
]

export const NANO = [
  'advertisment in conference program',
  'company profile, logo, and contact information on conference website',
]

export const PICO = ['advertisement in the abstract book']
